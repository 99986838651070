import { Reaction } from "../ChatBubble"

type Props = {
    reactions: Reaction[],
    display: boolean,
    setDisplay: Function,
    offset: number
}

export const ReactionsList: React.FC<Props> = ({ reactions, display, offset, setDisplay }) => {

    const computedTop = offset === -60 ? offset : offset === 0 ? offset + 80 : offset;

    const getTop = () => {
        let top;
        switch(offset){
            case 0:
                top = 60
                break;
            case 30:
                top = 80
                break;
            case 20:
                top = 80
                break;
            case -60:
                top = -140
                break;
            default:
                top = offset;
                break;    
        }
        return top ;
    };

    return (
        <div 
            className="reactions-list" 
            style={{ display: display ? "" : "none", position: 'absolute', top: getTop(), right: 0, zIndex: 20, height: '170px', overflowY: 'scroll' }}
            onMouseEnter={() => setDisplay(true)} 
            onMouseLeave={()=>setDisplay(false)}>
            <ul className="reactions-list-ul">
                {
                    reactions.map((reaction, index) => (
                        <li key={index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 20 }}>
                            <span title={reaction.displayName ? reaction.displayName : ""}>{reaction.displayName ? reaction.displayName.length > 10 ? `${reaction.displayName.slice(0, 10)}...` : reaction.displayName : ""}</span>
                            <img
                              src={reaction.emoji} 
                              alt={"Reaction Emoji"}
                              style={{ width: '40px', height: '40px' }} 
                            />
                        </li>
                    ))
                }
            </ul>
        </div>
    )
}