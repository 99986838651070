export const CustomEmojis = [
    {
        names: ['CatRoomba'],
        imgUrl:
            '/emojis/cat-roomba-exceptionally-fast.gif',
        id: 'cat'
    },
    {
        names: ['CoolDoge'],
        imgUrl:
          '/emojis/cool-doge.gif',
        id: 'cool-doge'
    },
    {
        names: ['CottonCandyGirl'],
        imgUrl:
          '/emojis/cotton-candy-girl.gif',
        id: 'cottonCandyGirl'
    },
    {
        names: ['Smart'],
        imgUrl:
            '/emojis/smart.gif',
        id: 'smart'
    },
    {
        names: ['ThisIsFine'],
        imgUrl:
            '/emojis/this-is-fine-fire.gif',
        id: 'fineFire'
    }
  ]