import React, { useState, useEffect, useRef } from 'react';
import Wheel from '@uiw/react-color-wheel'
import { hsvaToHex } from '@uiw/color-convert';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setPlayer } from '../../store/appUser';

type Props = {
    position: 'absolute' | 'relative' | 'fixed' | 'sticky' | 'static' | undefined,
    top: string,
    right: string,
    buttonClass: string
}
export const ColourPicker: React.FC<Props> = ({position,top,right, buttonClass}) => {

    const [hsva, setHsva] = useState({ h: 214, s: 43, v: 90, a: 1 });
    const [show, setShow] = useState(false);
    const { player } = useAppSelector(state => state.appUser);
    const playerRef = useRef(player);
    const dispatch = useAppDispatch();

    useEffect(() => {
        playerRef.current = player;
    },[player])

    function handleColourChange(color: { hsva: React.SetStateAction<{ h: number; s: number; v: number; a: number; }>; }){
        setHsva({ ...hsva, ...color.hsva })
        let stateToUpdate = {
            ...playerRef.current,
            colour: hsvaToHex(hsva)
        };
        console.log(stateToUpdate)
        dispatch(setPlayer(stateToUpdate))
    }

    return (
        <div style={{marginTop: '5px'}}>
            <button 
                onClick={() => setShow(!show)}
                className={buttonClass}
                style={{
                    backgroundColor: hsvaToHex(hsva)
                }}                
                >Colour</button>
                <div style={{position: position, right: right, top: top, zIndex: 250000}}>
                {
                    show && (
                    <><Wheel color={hsva} onChange={(color) => handleColourChange(color)} /></>
                    )
                }
                    
            </div>
        </div>
        
    )
}