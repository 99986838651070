import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { CSSPropertiesWithVars, PlayerData } from "./ChatBubble";
import axios from "axios";
import { SatelliteAlt, SatelliteAltTwoTone } from "@mui/icons-material";
import { User } from "../../../store/usersOnline";
import { setImages } from "../../../store/messages";


export const UsersOnline: React.FC = () => {


    const { users, partyUsers } = useAppSelector(state => state.usersOnline);
    const { player, update } = useAppSelector(state => state.appUser);
    const { images } = useAppSelector(state => state.messages);
    const { partyChat } = useAppSelector(state =>  state.party)
    const baseLeftPercentage = 380 / 1000 * 100;
    const incrementPercentage = 30 / 1000 * 100;
    const playerRef = useRef(player);
    const imagesRef = useRef(images);
    const userRef = useRef(users);
    const dispatch = useAppDispatch();

    //If party chat is enabled switch the ref for a different set of users (party people)
    useEffect(() => {
        if(partyChat){
            userRef.current = partyUsers
        } else {
            userRef.current = users
        }
    },[partyChat])

    // Keep the userRefs up to date when not in part mode and using stand user online array
    useEffect(() => {
        if(!partyChat){
            userRef.current = users
        }
    },[users])

    // same as above but with the flipped case
    useEffect(() => {
        if(partyChat){
            userRef.current = partyUsers
        }
    },[partyUsers])
    
    // update all other refs when chanmges are made
    useEffect(() => {
        playerRef.current = player;
        imagesRef.current = images;
    }, [player, images])

    // update all images when the ref changes
    useEffect(() => {
        userRef.current.forEach(user => {
            if (user.playerId !== "54321") {
                loadImage(user.avatarUrl.replace(".glb", ".png"), user.playerId);
            }
        })
    },[userRef.current])

    // listen for update firing so that new images can be collected (update is fired by readPlayerMe and The updateAvatar ws message)
    useEffect(() => {
        userRef.current.forEach(user => {
            if (user.playerId !== "54321") {
                loadImage(user.avatarUrl.replace(".glb", ".png"), user.playerId);
            }
        })
    }, [update])

    // load images on Mounting of component
    useEffect(() => {
        userRef.current.forEach(user => {
            if (user.playerId !== "54321") {
                loadImage(user.avatarUrl.replace(".glb", ".png"), user.playerId);
            }
        })
    }, [])

    // fetch the image from ready player me with no cache header to force update of png
    const loadImage = async (url: string, playerId: string) => {
        try {
            const response = await axios.get(url, {
                responseType: 'blob',
                headers: {
                    'Cache-Control': 'no-cache'
                }
            });

            const imageUrl = URL.createObjectURL(response.data);

            const imgElements = document.getElementsByClassName(playerId) as HTMLCollectionOf<HTMLImageElement>;

            for (let i = 0; i < imgElements.length; i++) {
                const imgElement = imgElements[i];
                imgElement.src = imageUrl;
            }
            updateImages(playerId, imageUrl)

        } catch (error) {
            console.error('Error loading image:', error);
        }
    };

    // update or store the imageUrl and playerId in redux to use in the chat bubble component
    const updateImages = (playerId: string, objectUrl: string) => {
        let currentImages = [...imagesRef.current];

        const existingUserIndex = currentImages.findIndex(existingUser => existingUser.playerId === playerId);

        if (existingUserIndex === -1) {
            currentImages.push({ playerId, imageObjecturl: objectUrl });
        } else {
            currentImages[existingUserIndex] = { playerId, imageObjecturl: objectUrl };
        }

        dispatch(setImages(currentImages));
    };


    
    return (
        <div>
            {userRef.current.map((user, index) => (
                index < 3 ?
                    <div className="image-container-online" style={
                        {
                            '--gradient-color': user.colour,
                            position: 'absolute',
                            borderRadius: '50%',
                            left: `${baseLeftPercentage + (index * incrementPercentage) + 40}%`,
                            zIndex: users.length + index,
                            top: '40px',
                        } as CSSPropertiesWithVars
                    }>
                        <div className="users-online-inner-image-container">
                            <img
                                className={`user-online-image ${user.playerId}`}
                                key={index}
                                alt=""
                                src={user.playerId === "54321" ? user.avatarUrl : ""}
                                style={{
                                    borderRadius: '50%',
                                }}
                            />
                        </div>
                    </div>
                    :
                    <div className="image-container-online" style={
                        {
                            '--gradient-color': "rgb(100,234,255)",
                            borderRadius: '50%',
                            position: 'absolute',
                            padding: '11px',
                            background: 'linear-gradient(349deg, var(--primary-color) 30%, var(--highlight-color) 85%)',
                            color: 'white',
                            left: `${baseLeftPercentage + (3 * incrementPercentage) + 40}%`,
                            zIndex: users.length + index,
                            top: '42px',
                            height: 20,
                            width: 20
                        } as CSSPropertiesWithVars
                    }>
                        +{(users.length) - 3}

                    </div>
            ))}
        </div>
    )
}