import React, { useEffect, useRef, useState } from "react";
import { badWords } from "./badwordlist";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { AcceptStatus, PartyMessage } from "../partyComponents/PartyTypes";
import { apiRequest } from "../partyComponents/RestCalls";
import { setMessageHistory, setPartyMessageHistory } from "../../../store/messages";
import { Reactions } from "./chatBubbleComponents.tsx/Reactions";
import { setIsInParty } from "../../../store/appUser";
import { setPartyChat } from "../../../store/party";

export enum PartyCallStatus {
    NOT_CONNECTED,
    CONNECTED,
    DISCONNECTED
}

export type Reaction = {
    playerId: string,
    displayName: string | null,
    emoji: string
}

export type msg = {
    id: string,
    msg: string,
    from: string,
    timestamp: string,
    playerId: string,
    avatar: string,
    responded?: boolean,
    partyMessage?: PartyMessage,
    reactions: Reaction[]
}

type chatBubbleProps = {
    index: number,
    msg: msg,
    lastMessageSameUser: boolean,
    isLastMessage: boolean,
    isFirstMessage: boolean,
    togglePartyChat: Function,
    selectedMessage: string | null,
    setSelectedMessage: Function,
    setShowReactionEmojis: Function,
    showReactionEmojis: boolean
}

var Filter = require('bad-words'),
    filter = new Filter();
badWords.forEach(word => {
    filter.addWords(word)
})

const isEmoji = (str: string) => {
    const regex = /[\p{Emoji}]/u;
    return regex.test(str);
}

const isValidMessage = (str: string) => {
    const regex = /[a-zA-Z]+/;
    return regex.test(str);
};

export const cleanMessage = (str: string) => {
    if (str != null && typeof str === 'string' && str.trim() !== '') {
        str = str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        return isEmoji(str) || !isValidMessage(str) ? str : filter.clean(str);
    }
    return '';
};

export interface PlayerData {
    color: string;
    avatarUrl: string;
}

export const userColorMap: { [key: string]: PlayerData } = {};

export interface CSSPropertiesWithVars extends React.CSSProperties {
    '--gradient-color'?: string;
}

const ChatBubble: React.FC<chatBubbleProps> = ({ index, msg, lastMessageSameUser, isLastMessage, isFirstMessage, togglePartyChat, selectedMessage, setSelectedMessage, setShowReactionEmojis, showReactionEmojis }) => {

    const { users } = useAppSelector(state => state.usersOnline);
    const { images } = useAppSelector(state => state.messages);
    const { player, sessionId } = useAppSelector(state => state.appUser);
    const { messageHistory, partyMessageHistory } = useAppSelector(state => state.messages);
    const [showReactions, setShowReactions] = useState(false)
    const usersOnlineRef = useRef(users);
    const selectedMessageRef = useRef(selectedMessage)
    const dispatch = useAppDispatch();

    // Update refs
    useEffect(() => {
        usersOnlineRef.current = users
        selectedMessageRef.current = selectedMessage
    }, [users, selectedMessage])

    // Get the color assigned to the user
    function getColours(msg: string, avatar: string): string {
        const user = users.find(user => user.playerId === msg);
        return user ? user.colour : "red";
    }

    //Check if the user status in the party list
    // function checkIsOnline(playerId: string): boolean {
    //     let players = window.Parties.getPlayerPartyList();
    //     if (players) {
    //         for (let player of players) {
    //             if (player.id === playerId ) {
    //                 switch (player.call_status) {
    //                     case "NOT_CONNECTED":
    //                         return false;
    //                     case "CONNECTED":
    //                         return true;
    //                     case "DISCONNECTED":
    //                         return false;
    //                     default:
    //                         return false;
    //                 }
    //             }
    //         }
    //     }
    //     return false;
    // }

    const bubbleStyle = {
        backgroundColor: 'rgba(3, 5, 29, 0.25)',
        padding: '10px',
        borderRadius: '5px',
        marginTop: '24px',
        fontSize: 'large'
    };

    const nameStyle = {
        fontWeight: 'bold',
        fontSize: 'small',
        color: getColours(msg.playerId, msg.avatar)
    };

    const timestampStyle = {
        color: 'lightgray',
        fontSize: 'small'
    };

    const getBubbleStyle = () => {
        let marginTop = 0;
        if (isFirstMessage) {
            marginTop = isLastMessage ? (index === 0 ? 70 : -45) : (index > 0 ? 20 : 0);
        } else if (isLastMessage) {
            marginTop = lastMessageSameUser ? (index > 0 ? -60 : 0) : (index > 0 ? 40 : 0);
        }
        return { marginTop };
    };

    const getImageContainerStyle = () => {
        let topValue;

        if (isLastMessage) {
            if (lastMessageSameUser) {
                topValue = index > 0 ? - 70 : -10;
            } else {
                topValue = index > 0 ? - 50 : -10;
            }

        } else {
            topValue = index > 0 ? -70 : 0;
        }

        return {
            '--gradient-color': getColours(msg.playerId, msg.avatar),
            top: topValue
        };
    };



    const loadImage = (playerId: string): string => {
        let playerIndex = images.findIndex(existingUser => existingUser.playerId === playerId);

        if (playerIndex !== -1) {
            return images[playerIndex].imageObjecturl;
        } else {
            return "https://models.readyplayer.me/66c870b093f9fe518a518f81.png";
        }
    };

    const formatUnityMessage = (msg: msg) => {
        if (msg.msg.includes("has requested to join")) {
            return <div style={bubbleStyle}>
                {msg.msg}
                {
                    msg.responded ? null : <div> <span style={{ color: 'green' }}>Would you like to accept?
                        <button onClick={() => handleInviteRequest(msg.partyMessage, AcceptStatus.ACCEPT_REQUEST)}>Yes</button>
                        <button onClick={() => handleInviteRequest(msg.partyMessage, AcceptStatus.REJECT_REQUEST)}>No</button>
                    </span></div>
                }
            </div>
        } else if (msg.msg.includes("You have been invited to")) {
            return <div style={bubbleStyle}>
                {msg.msg}
                {
                    msg.responded ? null : <div> <span style={{ color: 'green' }}>Would you like to accept?
                        <button onClick={() => handleInviteRequest(msg.partyMessage, AcceptStatus.ACCEPT_INVITE)}>Yes</button>
                        <button onClick={() => handleInviteRequest(msg.partyMessage, AcceptStatus.REJECT_INVITE)}>No</button>
                    </span></div>
                }
            </div>
        } else {
            return <div style={bubbleStyle}>
                {msg.msg}
            </div>
        }
    }

    const handleInviteRequest = (partyMessageIncoming: PartyMessage | undefined, type: AcceptStatus) => {
        if (partyMessageIncoming == undefined) {
            console.log("Party info is undefined")
            return
        } else {
            console.log(partyMessageIncoming)
            let partyMessage: PartyMessage = {
                fromPlayerId: partyMessageIncoming.toPlayerId,
                toPlayerId: partyMessageIncoming.fromPlayerId,
                partyInfo: partyMessageIncoming.partyInfo,
                fromPlayerToPeerId: "",
                textMessage: "",
                sessionId: sessionId
            }
            switch (type) {
                case AcceptStatus.ACCEPT_REQUEST:
                    apiRequest("/api/party/join-request/accept", { method: 'POST', body: partyMessage }).then(res => {
                        console.log(res.data)
                        dispatch(setIsInParty(true))
                        dispatch(setPartyChat(true))
                        togglePartyChat()
                    })
                    break;
                case AcceptStatus.REJECT_REQUEST:
                    apiRequest("/api/party/join-request/reject", { method: 'POST', body: partyMessage }).then(res => {
                        console.log(res.data)
                        dispatch(setIsInParty(false))
                        dispatch(setPartyChat(false))
                    })
                    break;
                case AcceptStatus.ACCEPT_INVITE:
                    apiRequest("/api/party/invite/accept", { method: 'POST', body: partyMessage }).then(res => {
                        console.log(res.data)
                        dispatch(setIsInParty(true))
                        dispatch(setPartyChat(true))
                        togglePartyChat()
                    })
                    break;
                case AcceptStatus.REJECT_INVITE:
                    apiRequest("/api/party/invite/reject", { method: 'POST', body: partyMessage }).then(res => {
                        console.log(res.data)
                        dispatch(setIsInParty(false))
                        dispatch(setPartyChat(false))
                    })
                    break;
                default:
                    console.log("no Status Passed into handle invite Request")
                    break;
            }
            updateMessageHistory(index, true)
        }
    }

    const updateMessageHistory = (index: number, responded: boolean) => {
        let updatedStateParty = partyMessageHistory.map((message: any, i: number) =>
            i === index ? { ...message, responded: responded  } : message
        )
        console.log(updatedStateParty)
        dispatch(setPartyMessageHistory(updatedStateParty));

        let updatedState = messageHistory.map((message: any, i: number) =>
            i === index ? { ...message, responded: responded  } : message
        )
        console.log(updatedState)
        dispatch(setMessageHistory(updatedState));
    };

    return (
        <div>
            <div className="outer-image-container">
                {isLastMessage && msg.playerId != "54321" &&  (
                    <div className="image-container" style={getImageContainerStyle()}>
                        <div className="image-inner-container">
                            <img alt="" className={`chat-bubble-image`} src={loadImage(msg.playerId)} title={msg.from} />
                        </div>
                        <div>
                            <span className={`dot-text-chat red-text-chat`}>
                                {'\u25CF'}
                            </span>
                        </div>
                    </div>
                )}

            </div>

            <div className="chat-bubble-container" style={{ marginBottom: 0 }}>
                {lastMessageSameUser && !isLastMessage ? (
                    <li className="chat-bubble">
                        {
                            msg.playerId === "54321" ? formatUnityMessage(msg) : <div style={bubbleStyle}>
                                {msg.msg}
                            </div>
                        }
                        <Reactions 
                            reactions={msg.reactions} 
                            msg={msg} 
                            offset={getBubbleStyle().marginTop}
                            selectedMessage={selectedMessageRef.current}
                            />
                        <div className="reactions-container" onMouseEnter={() => setShowReactions(true)} onMouseLeave={() => setShowReactions(false)}>
                            <button
                                style={{ all: "unset", display: showReactions ? "" : "none" }}
                                className="reactions"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    console.log("Clicked: " + msg.id);
                                    setShowReactionEmojis(true);
                                    setSelectedMessage(msg.id);
                                }}
                                >
                                +
                            </button>
                        </div>
                    </li>
                ) : (
                    <>
                {(isFirstMessage || isLastMessage) && (
                    <li className="chat-bubble" style={getBubbleStyle()}>
                        {isFirstMessage && (
                            <>
                                <span style={{ color: getColours(msg.playerId, msg.avatar) }}>{'\u25CF'} </span>
                                <span style={nameStyle}>
                                    {msg.from.length > 0 ? msg.from : "Guest"}
                                </span>
                                <span style={timestampStyle}> {msg.timestamp}<br /></span>
                            </>
                        )}
                        {
                            msg.playerId === "54321" ? formatUnityMessage(msg) : <div style={bubbleStyle}>
                                {msg.msg}
                            </div>
                        }
                        <Reactions 
                            reactions={msg.reactions} 
                            msg={msg} 
                            offset={ index === 0 ? 30 : getBubbleStyle().marginTop}
                            selectedMessage={selectedMessageRef.current}
                            />
                              <div className="reactions-container" onMouseEnter={() => setShowReactions(true)} onMouseLeave={() => setShowReactions(false)}>
                              <button
                                style={{ all: "unset", display: showReactions ? "" : "none" }}
                                className="reactions"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    console.log("Clicked: " + msg.id);
                                    setShowReactionEmojis(true);
                                    setSelectedMessage(msg.id);
                                }}
                                >
                                +
                            </button>
                            </div>
                            </li>
                        )}
                    </>
                )}
            </div>
        </div>

    );
}

export default ChatBubble;