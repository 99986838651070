type FetchOptions = {
  method: string;
  body?: object;
  headers?: HeadersInit;
};

export async function apiRequest<T>(
  endpoint: string,
  options: FetchOptions
): Promise<{ data: T | null; error?: string }> {
  try {
    const response = await fetch(process.env.REACT_APP_MOVEMENT_SERVER + endpoint, {
      method: options.method,
      headers: {
        'Content-Type': 'application/json',
        ...(options.headers || {})
      },
      body: options.body ? JSON.stringify(options.body) : undefined,
    });

    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }

    const data: T = await response.json();
    console.log(data);

    return { data };
  } catch (error) {
    let errorMessage = 'An unknown error occurred';
    if (error instanceof Error) {
      errorMessage = error.message;
    }
    return { data: null, error: errorMessage };
  }
}