import { useSDK } from "@metamask/sdk-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setConnected, setSignedToken, setWalletAddress } from "../../store/web3";
import { Header } from "./Header";

export const LoginPage:React.FC = () => {
    
    const [account, setAccount] = useState<string>();
    const { sdk, connected, connecting, provider, chainId } = useSDK();
    const {walletAddress, signedToken} = useAppSelector(state => state.web3)
    const [response, setResponse] = useState("")
    const history = useNavigate()
    const dispatch = useAppDispatch()
    
    const connectAndSign = async () => {
        try {
          const accounts = await sdk?.connect();
          if (!accounts || accounts.length === 0) {
            console.warn("No accounts found");
            return;
          }
          console.log(accounts)
          const walletAddress = accounts[0];
          setAccount(walletAddress);
          dispatch(setWalletAddress(walletAddress))
          const signResult = await sdk?.connectAndSign({
            msg: `this is a web3 test message`,
          });
      
          if (signResult) {
            console.log("Signed message token:", signResult);
            setResponse(signResult as string);
            dispatch(setSignedToken(signResult))
            dispatch(setConnected(connected))
            ServerCall(signResult as string, walletAddress)
            history("/home")
          }
        } catch (err) {
          console.warn("Failed to connect or sign message:", err);
        }
      };

      function ServerCall(signedToken: string, walletAddress: string){
        const headers = {
          'X-SIG': signedToken,
          'X-WAL': walletAddress,
          'X-MES': 'this is a web3 test message',
        };
        
        fetch(window.location.origin + "/mv", {
          method: 'GET', // Adjust the method if the endpoint requires a different HTTP method
          headers: headers,
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json(); // Parse JSON response if applicable
          })
          .then((data) => {
            console.log('Response:', data);
          })
          .catch((error) => {
            console.error('Fetch error:', error);
          });
      }

    return(
        <div>
          <Header/>
        <div className='video-container'>
            <div className='text-container-landing'>
            <h1 style={{ fontSize: '100px', textAlign: 'center'}}>Unleash Your Imagination</h1>
            <p className='landing-tag'>Explore, Play, and Connect in Our Virtual Worlds</p>
            <div style={{ 
                display: "flex", 
                justifyContent: "center", 
                alignItems: "center",  
                gap: "100px", 
                marginTop: "20px"
            }}>
                 <div className="login-elements-container">
                <img  src="/images/metamask.svg" alt="metamask" width="100" style={{margin: '0 auto'}} />
                <button className="join-button" onClick={() => history("/home")}>
                   Enter Site
                </button>
            </div>
            </div>
            
            </div>
            <video autoPlay muted loop preload='auto' style={{ zIndex: 0, height: '100vh' }}>
            <source src='/video/landing.mp4' type='video/webm' />
            Your browser does not support the video tag.
            </video>
            <div className="overlay"></div>
      </div>
      </div>      
    )
}