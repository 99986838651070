import { User } from "../../../store/usersOnline"
import { cleanMessage } from "./ChatBubble"


// Add Text Messages to the standard room scoped Message History
export function addMessage(currentMessages: Array<any>, data: any, getAvatar: Function): Array<any> {
    console.log(currentMessages)
    if (currentMessages.length > 200) {
        currentMessages.shift()
    }
    currentMessages.push(
        {
            id: data.id, 
            from: cleanMessage(data.from),
            msg: data.msg ? cleanMessage(data.msg) : cleanMessage(data.body),
            playerId: data.playerId ? data.playerId : "1234",
            timestamp: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
            avatar: getAvatar(data.playerId ? data.playerId : "1234"),
            reactions: [] 
        })

    return currentMessages
}

// Add Text Messages to the standard room scoped Message History
export function addPartyMessage(currentMessages: Array<any>, data: any, getAvatar: Function, usersOnline: User[]): Array<any> {
    console.log(usersOnline)
    console.log(data)
    let user = usersOnline.find(user => user.playerId === data.fromPlayerId);
    if (currentMessages.length > 200) {
        currentMessages.shift()
    }
    if(user){
        currentMessages.push(
            {
                from: cleanMessage(user.displayName),
                msg:  cleanMessage(data.textMessage),
                playerId: user.playerId ? user.playerId : "54321",
                timestamp: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
                avatar: getAvatar(user.playerId ? user.playerId : "54321"),
                reactions: [],
                partyMessage: data,
                responded: false 
            })
        return currentMessages
    }
    
    return currentMessages
}

export function addHost(data: any, getAvatar: Function, usersOnline: User[]): Array<any>{
    let partyPeople = []
    // add the host first as not present in the party people array
    partyPeople.push({
        timestamp: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
        playerId: data.partyInfo.hostPlayerId,
        displayName: "",
        avatarUrl: getAvatar(data.partyInfo.hostPlayerId ? data.partyInfo.hostPlayerId : "54321"),
        avatarProfilePic: null,
        radius: 0,
        height: 0,
        defaultAvatarType: "",
        colour: usersOnline.find(user => user.playerId === data.partyInfo.hostPlayerId)?.colour
    })
    return partyPeople
}

export function addPartyPeople(data: any, getAvatar: Function, usersOnline: User[], currentPartyPeople: any[]): Array<any> {
    let partyPeople = [...currentPartyPeople]
    // add the rest
    for (let partyPerson of data.partyInfo.partyPeople) {
        partyPeople.push({
            timestamp: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
            playerId: partyPerson.playerId,
            displayName: "",
            avatarUrl: getAvatar(partyPerson.playerId ? partyPerson.playerId : "54321"),
            avatarProfilePic: null,
            radius: 0,
            height: 0,
            defaultAvatarType: "",
            colour: usersOnline.find(user => user.playerId === partyPerson.playerId)?.colour
        })
    }
    return partyPeople
}

export function removeDuplicatesByPlayerId(partyPeople: User[]){
    const seenPlayerIds = new Set<string>();
    return partyPeople.filter(user => {
      if (seenPlayerIds.has(user.playerId)) {
        return false; 
      } else {
        seenPlayerIds.add(user.playerId);
        return true; 
      }
    });
  };

export function hasUserJoined(partyPeople: User[], playerId: string):boolean{
    let playerExists = partyPeople.some(
        (user: User) => user.playerId === playerId
    );
    return playerExists
}