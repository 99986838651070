import { createSlice } from "@reduxjs/toolkit";
import { PartyMessage } from "../components/LiveChat/partyComponents/PartyTypes";
import { Reaction } from "../components/LiveChat/chatWindowComponents/ChatBubble";

export interface Message {
    id: string,
    from: string,
    playerId: string,
    msg: string,
    timestamp: string,
    avatar: string,
    reactions: Reaction[],
    partyInfo?: PartyMessage,
    responded?: boolean
}

export interface MessageImages {
    playerId: string,
    imageObjecturl: string
}

interface MessageState {
    messageHistory: Message[],
    partyMessageHistory: Message[],
    images: MessageImages[],
    unityMessage: string
}

const initialState: MessageState = {
    messageHistory: [
        {
            id: "1",
            from: "System",
            playerId: "54321",
            msg: "Welcome to Chat",
            timestamp: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
            avatar: "https://models.readyplayer.me/66a7a3fc922f759d2bf57a7b.png",
            reactions: []
        }
    ],
    partyMessageHistory: [
        {
            id: "2",
            from: "System",
            playerId: "54321",
            msg: "Welcome to The Party Chat",
            timestamp: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
            avatar: "https://models.readyplayer.me/66a7a3fc922f759d2bf57a7b.png",
            reactions: []
        }
    ],
    images: [
        {
            playerId: "54321",
            imageObjecturl: "https://models.readyplayer.me/66a7a3fc922f759d2bf57a7b.png"
        }
    ],
    unityMessage: ""
}

export const messagesSlice = createSlice({
    name: "messages",
    initialState,
    reducers: {
        setMessageHistory: (state, action) => {
            state.messageHistory = action.payload
        },
        setPartyMessageHistory: (state, action)=> {
            state.partyMessageHistory = action.payload
        },
        setImages: (state, action) => {
            state.images = action.payload
        },
        setUnityMessage: (state, action) => {
            state.unityMessage = action.payload
        }
    }
});

export const { setMessageHistory, setImages, setUnityMessage, setPartyMessageHistory } = messagesSlice.actions;

export default messagesSlice.reducer;