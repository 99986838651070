import { Avatar } from "@readyplayerme/visage";
import React from "react";
import { useAppSelector } from "../../store/hooks";



export const AvatarDisplay: React.FC = () => {

    const { player } = useAppSelector(state => state.appUser)

    return(
        <div className="avatar-display">
            <Avatar modelSrc={player.glbUrl ? player.glbUrl  : ""}  />
        </div>
    )
}