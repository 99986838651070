import React, { useEffect } from 'react';
import "./styles/Landing.css";
import { SceneCardManager } from '../SceneCards/SceneCardManager';
import { Header } from './Header';
import  "../HeadlineCards/HeadlineCards.css"

const WelcomePage: React.FC = () => {
 
  useEffect(() => {
    if (window?.ethereum?.selectedAddress) {
      console.log("Address")
      console.log(window.ethereum.selectedAddress)
    }
  })

    return(
        <div className='App'>  
        <Header />
            <div className='headline-card-container'>
                <SceneCardManager />
            </div>
        </div>
    )
}

export default WelcomePage;
