import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { msg, Reaction } from "../ChatBubble"
import { ReactionsList } from "./ReactionsList";


type Props = {
    reactions: Reaction[],
    msg: msg,
    offset: number,
    selectedMessage: string | null
}

export const Reactions: React.FC<Props> = ({ reactions, msg,offset, selectedMessage }) => {

    const { player } = useAppSelector(state => state.appUser);
    const { messageHistory } = useAppSelector(state => state.messages)
    const [showReactions, setShowReactions] = useState(false)
    const messageRef = useRef(messageHistory)
    const playerRef = useRef(player)

    // Update refs
    useEffect(() => {
        messageRef.current = messageHistory
        playerRef.current = player
    }, [messageHistory, player, selectedMessage])


    return (
        <>
            {
                msg.reactions.length > 0 ? (
                    <div className="reaction-div" onMouseEnter={() => setShowReactions(true)} onMouseLeave={()=>setShowReactions(false)}>
                    {
                      Object.entries(
                        msg.reactions.reduce((acc: { [emoji: string]: number }, reaction) => {
                          acc[reaction.emoji] = (acc[reaction.emoji] || 0) + 1;
                          return acc;
                        }, {})
                      )
                        .slice(0, 5)
                        .map(([emoji, count], index) => (
                          <span key={index} style={{ display: 'inline-block', margin: '0px 3px' }}>
                            <img
                              src={emoji} 
                              alt={"Reaction Emoji"}
                              style={{ width: '14px', height: '14px' }} 
                            />
                            {count > 1 ? `+${count - 1}` : ''}
                          </span>
                        ))
                    }
                  
                    {
                      Object.entries(
                        msg.reactions.reduce((acc: { [emoji: string]: number }, reaction) => {
                          acc[reaction.emoji] = (acc[reaction.emoji] || 0) + 1;
                          return acc;
                        }, {})
                      ).length > 5 && (
                        <span className="ellipsis">...</span>
                      )
                    }
                  </div>
                  
                ) : null
            }
            <ReactionsList display={showReactions} reactions={reactions} offset={offset} setDisplay={setShowReactions}/>
            
        </>
    )
}