import { createSlice} from "@reduxjs/toolkit";

interface userMessage {
    guid: string,
    mesg: string, 
    timestamp: string
}

export interface user {
    displayName: string | null,
    glbUrl: string | null,
    avatarProfilePic: string | null,
    disabled: boolean,
    hashedEmail: string,
    created: number,
    createdOffset: string,
    createdZone: string,
    authToken: string,
    playerId: string,
    colour: string
}

interface AppUserState {
    chatHandle: string,
    userMessages: userMessage[],
    player: user,
    id: string,
    update: boolean,
    sessionId: string,
    isInParty: boolean,
    showAlert: boolean
}

const initialState: AppUserState = {
    chatHandle: "",
    userMessages: [],
    player: {
        displayName: null,
        glbUrl: "https://models.readyplayer.me/66c870b093f9fe518a518f81.glb",
        avatarProfilePic: null,
        disabled: false,
        hashedEmail: "",
        created: -1,
        createdOffset: "",
        createdZone: "",
        authToken: "",
        playerId: "",
        colour: "#fff"
    },
    id: "",
    update: false,
    sessionId: "",
    isInParty: false,
    showAlert: false
}
export const appUserSlice = createSlice({
    name:"appUser",
    initialState,
    reducers: {
        setUserMessages: (state, action) => {
            state.userMessages = action.payload
        },
        setChatHandle: (state, action) => {
            state.chatHandle = action.payload
        },
        setPlayer: (state, action) => {
            state.player = action.payload
        },
        setId: (state, action) => {
            state.id = action.payload
        },
        setUpdate: (state, action) => {
            state.update = action.payload
        },
        setSessionId: (state, action) => {
            state.sessionId = action.payload
        },
        setIsInParty: (state, action) => {
            state.isInParty = action.payload
        },
        setShowAlert: (state, action) => {
            state.showAlert = action.payload
        }
    }
});

export const { setId, setUserMessages, setChatHandle, setPlayer, setUpdate, setSessionId, setIsInParty, setShowAlert } = appUserSlice.actions;

export default appUserSlice.reducer;